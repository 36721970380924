import { useOidcUser } from '@axa-fr/react-oidc';
import { Button, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import {
    getExceptionDetails,
    toastNotifications,
    variants,
    sendTrackingEvent,
    FileUpload,
    FileUploadFile
} from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentType, OnboardingRequestDetailModel } from 'api/models';
import { useDeleteAttachmentContent, useUploadAttachmentContent } from 'api/queries/attachments/attachments';
import {
    getGetRequestByIdQueryKey,
    getGetRequestDetailQueryKey,
    getGetRequestsQueryKey,
    useSaveRequestDraft,
    useSubmitRequest
} from 'api/queries/requests/requests';
import { getGetStepsQueryKey } from 'api/queries/steps/steps';
import { TrackingEvents } from 'utils/trackingsEvents';
import { CompanyBaseData } from './CompanyBaseData';
import { companyToRequest, getInitialRequestValues } from './companyFormMappings';
import { requestSchema, RequestSchemaType } from './companyFormSchema';
import { CountryRule, FieldConfigurations } from './CountryRule';

type Props = {
    request: OnboardingRequestDetailModel;
    className?: string;
    readOnly?: boolean;
    mode?: 'Frontend' | 'Backoffice' | 'Invitation';
    onClose: () => void;
};

const clearInvisibleFields = (values: RequestSchemaType) => {
    const countryRule = CountryRule.FindById(values.companyData.country);

    if (!countryRule) {
        return;
    }

    Object.keys(values.companyData).forEach((key) => {
        if (countryRule.fieldConfigurations[key as keyof FieldConfigurations]?.visible === false) {
            (values.companyData as Record<string, unknown>)[key] = undefined;
        }
        if (values.companyData[key as keyof typeof values.companyData] === '') {
            (values.companyData as Record<string, unknown>)[key] = undefined;
        }
    });
};
type UploadableAttachment = 'BusinessLicense' | 'VATCertificate' | 'RegisteredNationalAddress';
export const CompanyForm = ({ request, className, onClose, readOnly = false, mode = 'Frontend' }: Props) => {
    const requestId = request.id;

    const { t } = useTranslation();
    const { oidcUser } = useOidcUser();

    const initialValues = getInitialRequestValues(request, oidcUser?.email?.toString().split(',')[0]);

    const form = useForm<RequestSchemaType>({
        mode: 'controlled',
        validate: zodResolver(requestSchema),
        validateInputOnChange: true,
        initialValues
    });

    const {
        getInputProps,
        onSubmit: onSubmitForm,
        setValues,
        setFieldValue,
        isDirty,
        isValid,
        resetDirty,
        getValues
    } = form;

    const queryClient = useQueryClient();
    const [previousRequest, setPreviousRequest] = useState(request);

    const [files, setFiles] = useState<Record<string, File | null>>({
        BusinessLicense: null,
        VATCertificate: null,
        RegisteredNationalAddress: null
    });

    const { mutateAsync: saveRequestDraft, isPending: isSaveRequestDraftLoading } = useSaveRequestDraft();
    const { mutateAsync: submitRequest, isPending: isSubmitRequestLoading } = useSubmitRequest();
    const { mutateAsync: uploadAttachmentContent, isPending: isUploadAttachmentContentLoading } =
        useUploadAttachmentContent();
    const { mutateAsync: deleteAttachmentContentMutation, isPending: isDeleteAttachmentContentLoading } =
        useDeleteAttachmentContent();

    const isLoading =
        isSaveRequestDraftLoading ||
        isSubmitRequestLoading ||
        isUploadAttachmentContentLoading ||
        isDeleteAttachmentContentLoading;

    const countryRule = CountryRule.FindById(form.values.companyData.country);

    if (request !== previousRequest) {
        setValues(getInitialRequestValues(request));
        resetDirty();
        setPreviousRequest(request);
    }

    const handleSubmit = async (values: RequestSchemaType) => {
        sendTrackingEvent(TrackingEvents.RequestSent);
        clearInvisibleFields(values);
        try {
            await handleDeleteFile(values);
            if (Object.values(files).some(Boolean)) {
                await uploadFiles(values);
            }
            await submitRequest({
                id: request.id,
                data: companyToRequest(values)
            });

            resetDirty();
            queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetRequestByIdQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetStepsQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
            toastNotifications.success({ title: t('success'), message: t('dataSaved', { data: t('company') }) });
            onClose();
        } catch (e) {
            toastNotifications.error({
                title: t('failed'),
                message: t('dataNotSaved', { data: t('company'), reason: getExceptionDetails(e)?.message })
            });
        }
    };

    const handleDiscardChanges = async () => {
        setValues(getInitialRequestValues(request));
        resetDirty();
        onClose();
    };

    const handleFileChange = (type: UploadableAttachment) => (file: File | null) => {
        setFiles((prev) => ({ ...prev, [type]: file }));
        setFieldValue(type, file ? file.name : undefined);
    };

    const handleFileRemove = (type: UploadableAttachment) => () => {
        setFiles((prev) => ({ ...prev, [type]: null }));
        setFieldValue(type, undefined);
    };

    const handleDeleteFile = async (formValues: RequestSchemaType) => {
        const toDelete = getRemovedAttachments(formValues);
        for (const type of toDelete) {
            const attachment = request.attachments?.find((att) => att.attachmentType === AttachmentType[type]);
            if (attachment?.id) {
                await deleteAttachmentContentMutation({
                    id: requestId,
                    attachmentId: attachment.id
                });
            }
        }
    };

    const handleSaveDraft = async (values: RequestSchemaType) => {
        sendTrackingEvent(TrackingEvents.DraftSaved);

        clearInvisibleFields(values);
        try {
            await handleDeleteFile(values);
            if (Object.values(files).some(Boolean)) {
                await uploadFiles(values);
            }
            await saveRequestDraft({
                id: requestId,
                data: companyToRequest(values)
            });
            resetDirty();
            await queryClient.invalidateQueries({ queryKey: ['get-attachments', requestId] });
            queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetStepsQueryKey(requestId) });
            toastNotifications.success({ title: t('success'), message: t('dataSaved', { data: t('company') }) });
            onClose();
        } catch (e) {
            toastNotifications.error({
                title: t('failed'),
                message: t('dataNotSaved', { data: t('company'), reason: getExceptionDetails(e)?.message })
            });
        }
    };

    const uploadFiles = async (values: RequestSchemaType) => {
        const uploadableTypes: UploadableAttachment[] = [
            'BusinessLicense',
            'VATCertificate',
            'RegisteredNationalAddress'
        ];

        for (const type of uploadableTypes) {
            const file = files[type];
            const valueInForm = values[type];

            if (!file || !valueInForm) {
                continue;
            }

            const attachmentId = request?.attachments?.find(
                (attachment) => attachment.attachmentType === AttachmentType[type]
            )?.id;

            if (attachmentId) {
                await uploadAttachmentContent({
                    id: requestId,
                    attachmentId,
                    data: { File: file }
                });
            }
        }
    };

    const getRemovedAttachments = (formValues: RequestSchemaType): UploadableAttachment[] => {
        return (['BusinessLicense', 'VATCertificate', 'RegisteredNationalAddress'] as UploadableAttachment[]).filter(
            (type) => {
                const attachmentExistsInRequest = request.attachments?.some(
                    (att) => att.attachmentType === AttachmentType[type] && att.exists
                );

                const isNowMissingInForm = !formValues[type];

                return attachmentExistsInRequest && isNowMissingInForm;
            }
        );
    };

    const getDisplayedFile = (type: UploadableAttachment): FileUploadFile[] => {
        const file = files[type];
        if (file) {
            return [{ name: file.name, size: file.size }];
        }

        const valueInForm = form.values[type];
        if (!valueInForm) {
            return [];
        }

        const attachment = request.attachments?.find(
            (att) => att.attachmentType === AttachmentType[type] && att.exists
        );

        if (attachment) {
            return [{ name: attachment.fileName ?? '', size: attachment.fileSizeBytes ?? undefined }];
        }

        return [];
    };

    return (
        <form className={clsx(className, 'flex w-full flex-col gap-4')} onSubmit={onSubmitForm(handleSubmit)}>
            <CompanyBaseData form={form} readOnly={readOnly} />

            <h4 className="text-base-bold -mb-2 mt-6">{t('keyUser')}</h4>
            <>
                <TextInput
                    description={t('technicalAdministratorDescription')}
                    label={t('technicalAdministrator')}
                    placeholder={t('enterPlaceholder', { label: t('technicalAdministrator') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('technicalAdministrator')}
                />

                <TextInput
                    description={t('contractSignatoryDescription')}
                    label={t('contractSignatory')}
                    placeholder={t('enterPlaceholder', { label: t('contractSignatory') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('contractSignatory')}
                />
            </>
            {mode === 'Frontend' && countryRule?.fieldConfigurations.businessLicenseUpload.visible && (
                <>
                    <h4 className="text-base-bold -mb-2 mt-6">{t('documents')}</h4>
                    <FileUpload
                        description={t('documentUploadDescription')}
                        files={getDisplayedFile('BusinessLicense')}
                        label={t('businessLicence')}
                        required={countryRule?.fieldConfigurations.businessLicenseUpload.mandatory}
                        {...getInputProps('BusinessLicence')}
                        mode="single"
                        onFileSelected={handleFileChange('BusinessLicense')}
                        onRemoveFile={handleFileRemove('BusinessLicense')}
                    />
                </>
            )}

            {mode === 'Frontend' && countryRule?.fieldConfigurations.VATCertificateUpload.visible && (
                <>
                    <FileUpload
                        description={t('documentUploadDescription')}
                        files={getDisplayedFile('VATCertificate')}
                        label={t('vatCertificate')}
                        required={countryRule?.fieldConfigurations.VATCertificateUpload.mandatory}
                        {...getInputProps('VATCertificate')}
                        mode="single"
                        onFileSelected={handleFileChange('VATCertificate')}
                        onRemoveFile={handleFileRemove('VATCertificate')}
                    />
                </>
            )}
            {mode === 'Frontend' && countryRule?.fieldConfigurations.RegisteredNationalAddressUpload.visible && (
                <>
                    <FileUpload
                        description={t('documentUploadDescription')}
                        files={getDisplayedFile('RegisteredNationalAddress')}
                        label={t('RegisteredNationalAddress')}
                        required={countryRule?.fieldConfigurations.RegisteredNationalAddressUpload.mandatory}
                        {...getInputProps('RegisteredNationalAddress')}
                        mode="single"
                        onFileSelected={handleFileChange('RegisteredNationalAddress')}
                        onRemoveFile={handleFileRemove('RegisteredNationalAddress')}
                    />
                </>
            )}
            {/* {Object.keys(form.errors).length > 0 && (
                <div className="mt-4 text-red-500">
                    {Object.keys(form.errors).map((key) => (
                        <div key={key}>{form.errors[key]}</div>
                    ))}
                </div>
            )} */}

            <div className="modal-footer mt-2">
                {mode === 'Frontend' && (
                    <>
                        <Button
                            disabled={!isDirty()}
                            loading={isLoading}
                            variant={variants.button.secondary}
                            onClick={() => handleSaveDraft(getValues())}
                        >
                            {t('saveDraft')}
                        </Button>
                        <Button disabled={!isValid()} loading={isLoading} type="submit">
                            {t('submit')}
                        </Button>
                    </>
                )}
                {mode === 'Backoffice' && !readOnly && (
                    <>
                        <Button
                            disabled={!isDirty()}
                            loading={isLoading}
                            variant={variants.button.secondary}
                            onClick={handleDiscardChanges}
                        >
                            {t('discardChanges')}
                        </Button>
                        <Button disabled={!isValid() || !isDirty()} loading={isLoading} type="submit">
                            {t('saveChanges')}
                        </Button>
                    </>
                )}
            </div>
        </form>
    );
};
