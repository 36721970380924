import { useAcceptInvitation } from '@shared/api/queries/invitations/invitations';
import { ErrorView, LoadingAnimation, MessageView } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router';

import invitationExpired from './assets/expired.svg';

export const Invitation = () => {
    const { requestId = '' } = useParams();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const [error, setError] = useState<string | undefined>(undefined);

    const navigate = useNavigate();
    const { mutateAsync, isIdle, status } = useAcceptInvitation();

    if (!searchParams.get('key')) {
        return <ErrorView description={t('keyMissing')} />;
    }

    if (isIdle) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mutateAsync({ id: requestId, params: { key: searchParams.get('key')! } })
            .then((response) => navigate(`/request/${response.data?.id}`))
            .catch(() => setError(t('invitationExpired')));
    }

    if (status === 'success') {
        navigate(`/request/${requestId}`);
    }

    if (error) {
        return (
            <MessageView
                actionText={t('startNewRegistration')}
                description={t('invitationExpiredDescription')}
                image={invitationExpired}
                title={error}
                fullWidth
                onActionClick={() => navigate('/')}
            />
        );
    }

    return <LoadingAnimation text={t('acceptingInvitation')} />;
};
