import { Button } from '@mantine/core';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { OnboardingRequestDetailModel } from 'api/models';
import { CompanyInformation } from './CompanyInformation';
import { RequestAttachments } from './RequestAttachments';

type Props = {
    request: OnboardingRequestDetailModel;
    className?: string;
    onClose?: () => void;
};

export const ViewCompany = ({ request, className, onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={clsx(className, 'flex w-full flex-col gap-4')}>
            <CompanyInformation mode="Frontend" request={request} />
            <RequestAttachments request={request} />
            {onClose && (
                <Button className="modal-action ml-auto mt-2" onClick={onClose}>
                    {t('close')}
                </Button>
            )}
        </div>
    );
};
