import { BaseLayout, UserContextMenuItem, Notifications } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router';

import { useGetInboxToken } from 'notificationsApi/queries/inbox/inbox';
import { getSenderImage } from 'notificationsApi/queries/messages/messages';
import { knockConfiguration } from 'shared/utils';

export const OnboardingFrame = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: inboxToken, isLoading: isInboxTokenPending } = useGetInboxToken();

    const getUserImageUrl = async (userId: string) => {
        const senderImageModel = await getSenderImage(userId);

        return senderImageModel?.data?.imageUri ?? undefined;
    };

    return (
        <BaseLayout
            background="gray"
            className="bg-background-gray-ultra-light"
            innerClassName="p-6 h-full mx-auto max-w-[1680px] bg-background-gray-ultra-light"
            versionNumber={APP_VERSION}
        >
            <UserContextMenuItem
                icon="domain"
                title={t('myRegistrations')}
                onClick={() => navigate('/manageRequests')}
            />
            {inboxToken?.data && (
                <Notifications
                    getUserImageUrl={getUserImageUrl}
                    isLoading={isInboxTokenPending}
                    userId={inboxToken.data.userIdentifier}
                    userToken={inboxToken.data.token}
                    {...knockConfiguration}
                />
            )}
            <Outlet />
        </BaseLayout>
    );
};
