import { useGetRequestById } from '@shared/api/queries/requests/requests';
import { useGetSteps } from '@shared/api/queries/steps/steps';
import { ErrorView, Icon, Layout, LoadingAnimation, Timeline, useElementSize } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link } from 'react-router';

import { orderBy } from 'shared/utils';
import { Step } from './components/Step';

export const Request = () => {
    const { t } = useTranslation();
    const { requestId = '' } = useParams();
    const { ref, width } = useElementSize();
    const navigate = useNavigate();
    const layout = width < 800 && ref ? Layout.OneColumn : Layout.TwoColumns;

    const { data: steps, isLoading: areStepsLoading, error: stepsError } = useGetSteps(requestId);
    const { data: request, isLoading: isRequestLoading, isError: isRequestError } = useGetRequestById(requestId);

    if (isRequestError) {
        navigate('/request/not-found-expired');
    }

    if (stepsError) {
        return <ErrorView description={t('errorLoadingSteps')} title={t('somethingWentWrong')} />;
    }

    return (
        <>
            <Link className="text-base flex gap-sm text-text-accent" to="/manageRequests">
                <Icon icon="arrow_back" />
                <p>{t('back')}</p>
            </Link>
            <div className="mb-7">
                <h1 className="text-4xl font-default font-semibold leading-4xl text-text-default md:text-4xl md:p-0">
                    {t('registerCompany')}
                </h1>
                <p className="font-base text-base font-normal text-text-light md:p-0">{t('enterYourData')}</p>
            </div>
            <div ref={ref} className="m-auto max-w-[1564px] pb-16">
                <Timeline className="m-2" layout={layout}>
                    {(areStepsLoading || isRequestLoading) && <LoadingAnimation />}
                    {steps &&
                        steps.data &&
                        request &&
                        request.data &&
                        orderBy(steps.data, ['order']).map((step) => (
                            <Step key={step.order} request={request.data} step={step} />
                        ))}
                </Timeline>
            </div>
        </>
    );
};
