import { InputWrapper, MantineThemeOverride } from '@mantine/core';

export const getCustomThemeOverrides = (): MantineThemeOverride => ({
    components: {
        InputWrapper: InputWrapper.extend({
            defaultProps: {
                unstyled: true
            },
            classNames: {
                required: 'text-text-alert -ml-1'
            }
        })
    }
});
