import { useOidcUser } from '@axa-fr/react-oidc';
import { TextInput, InputBase, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { classNames, Select } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { default as PhoneInput } from 'react-phone-number-input/input';

import { EInvoiceType } from 'api/models';
import { useGetCountries } from 'api/queries/countries/countries';
import { useGetIndustrialSectors } from 'api/queries/industrial-sectors/industrial-sectors';
import { InvitationSchemaType, RequestSchemaType } from './companyFormSchema';
import { CountryRule } from './CountryRule';

type Props = {
    form: Pick<
        UseFormReturnType<RequestSchemaType | InvitationSchemaType>,
        'getInputProps' | 'isDirty' | 'validateField' | 'values' | 'validate' | 'errors'
    >;
    readOnly?: boolean;
};
function validateNonEmptyFields(
    form: Pick<UseFormReturnType<RequestSchemaType | InvitationSchemaType>, 'values' | 'validateField'>
) {
    Object.keys(form.values.companyData).forEach((key) => {
        const value = form.values.companyData[key as keyof typeof form.values.companyData];
        if (!!value && value !== '') {
            form.validateField(`companyData.${key}`);
        }
    });
}

export const CompanyBaseData = ({ form, readOnly }: Props) => {
    const { t } = useTranslation();
    const { oidcUser } = useOidcUser();
    const { data: countriesResponse, isPending: areCountriesLoading } = useGetCountries();
    const { data: industrialSectorsResponse, isPending: areIndustrialSectorsLoading } = useGetIndustrialSectors({
        request: { headers: { 'Accept-Language': oidcUser.locale } }
    });
    const countries = countriesResponse?.data || [];
    const industrialSectors = industrialSectorsResponse?.data || [];
    const debounceOnChangeString = useDebouncedCallback((value: string, onChangeFunction: (value: string) => void) => {
        onChangeFunction(value);
    }, 1);

    const sortedCountries = countries
        .map(({ id, name, countryCode }) => ({
            value: id,
            label: name || countryCode
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    const countryRule = CountryRule.FindById(form.values.companyData.country);
    const vatIdProps = form.getInputProps('companyData.vatId');
    const countryProps = form.getInputProps('companyData.country');
    const useElectronicInvoiceProps = form.getInputProps('companyData.useElectronicInvoice');
    const phoneNumberProps = form.getInputProps('companyData.phoneNumber');
    const mobileNumberProps = form.getInputProps('companyData.mobileNumber');

    if (countryRule?.isFieldMandatory('useElectronicInvoice') && !useElectronicInvoiceProps.value) {
        useElectronicInvoiceProps.onChange(true);
    }

    return (
        <>
            <div className="flex flex-col gap-1">
                <Select
                    className="flex-grow"
                    data={sortedCountries}
                    disabled={areCountriesLoading || countries.length <= 0}
                    label={t('country')}
                    maxDropdownHeight={200}
                    placeholder={t('selectPlaceholder', { label: t('country') })}
                    value={form.values.companyData.country}
                    required
                    {...countryProps}
                    searchable
                    onChange={(value) => {
                        countryProps.onChange(value);
                        validateNonEmptyFields(form);
                    }}
                />
            </div>
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow"
                    label={t('companyName')}
                    placeholder={t('enterPlaceholder', { label: t('companyName') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.companyName')}
                />
            </div>
            <div className="flex gap-4">
                {countryRule?.isFieldVisible('companyOwnerName') && (
                    <TextInput
                        className="flex-grow"
                        label={t('companyOwnerName')}
                        placeholder={t('enterPlaceholder', { label: t('companyOwnerName') })}
                        readOnly={readOnly}
                        {...form.getInputProps('companyData.companyOwnerName')}
                    />
                )}
            </div>
            <Select
                data={industrialSectors.map(({ identifier, translation }) => ({
                    value: identifier,
                    label: translation || identifier
                }))}
                disabled={areIndustrialSectorsLoading || industrialSectors.length <= 0}
                label={t('industrialSector')}
                maxDropdownHeight={200}
                placeholder={t('selectPlaceholder', { label: t('industrialSector') })}
                readOnly={readOnly}
                required={countryRule?.isFieldMandatory('industrialSector')}
                {...form.getInputProps('companyData.industrialSector')}
            />
            <h4 className="text-base-bold -mb-2 mt-6">{t('address')}</h4>

            <div className="flex gap-4">
                <TextInput
                    className="flex-grow"
                    label={t('streetAddress')}
                    placeholder={t('enterPlaceholder', { label: t('streetAddress') })}
                    readOnly={readOnly}
                    required={countryRule?.isFieldMandatory('street')}
                    {...form.getInputProps('companyData.street')}
                />
            </div>
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow-0"
                    label={t('zipCode')}
                    placeholder={t('enterPlaceholder', { label: t('zipCode') })}
                    readOnly={readOnly}
                    required={countryRule?.isFieldMandatory('zipCode')}
                    {...form.getInputProps('companyData.zipCode')}
                />
                <TextInput
                    className="flex-grow"
                    label={t('city')}
                    placeholder={t('enterPlaceholder', { label: t('city') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.city')}
                />
                {countryRule?.isFieldVisible('state') && (
                    <TextInput
                        className="flex-grow"
                        label={t('state')}
                        placeholder={t('enterPlaceholder', { label: t('state') })}
                        readOnly={readOnly}
                        required={countryRule?.isFieldMandatory('state')}
                        {...form.getInputProps('companyData.state')}
                    />
                )}
            </div>
            <h4 className="text-base-bold -mb-2 mt-6">{t('financialInformation')}</h4>
            <Switch
                checked={useElectronicInvoiceProps.value}
                disabled={countryRule?.isFieldMandatory('useElectronicInvoice')}
                label={t('digitalInvoice')}
                {...useElectronicInvoiceProps}
                onChange={(value) => {
                    useElectronicInvoiceProps.onChange(value);
                    form.validate();
                    if (!value) {
                        form.getInputProps('companyData.electronicInvoiceType').onChange(null);
                        form.getInputProps('companyData.electronicInvoiceEmail').onChange('');
                    }
                }}
            />
            {(countryRule?.isFieldMandatory('useElectronicInvoice') || useElectronicInvoiceProps.value) && (
                <div className="flex flex-col gap-4">
                    <Select
                        className="basis-2/12"
                        data={(countryRule?.getEInvoiceTypes() ?? Object.values(EInvoiceType)).map((type) => ({
                            label: t(`eInvoiceType.${type}`),
                            value: useElectronicInvoiceProps.value ? type.toString() : ''
                        }))}
                        label={t('invoiceFormat')}
                        required={form.values.companyData.useElectronicInvoice}
                        searchable={false}
                        {...form.getInputProps('companyData.electronicInvoiceType')}
                    />
                    {countryRule?.isFieldVisible('sdiCode') && (
                        <TextInput
                            className="basis-4/12"
                            label={t('sdiCode')}
                            placeholder={t('enterPlaceholder', { label: t('sdiCode') })}
                            readOnly={readOnly}
                            required={countryRule?.isFieldMandatory('sdiCode')}
                            {...form.getInputProps('companyData.sdiCode')}
                        />
                    )}
                    <TextInput
                        className="flex-grow"
                        label={t('electronicInvoiceEmail')}
                        placeholder={t('enterPlaceholder', { label: t('electronicInvoiceEmail') })}
                        readOnly={readOnly}
                        required={form.values.companyData.useElectronicInvoice}
                        {...form.getInputProps('companyData.electronicInvoiceEmail')}
                    />
                </div>
            )}
            <div className="flex gap-4">
                {countryRule?.isFieldVisible('vatId') && (
                    <TextInput
                        className="flex-grow"
                        label={t('vatId')}
                        placeholder={t('enterPlaceholder', { label: t('vatId') })}
                        readOnly={readOnly}
                        required={countryRule?.isFieldMandatory('vatId')}
                        {...vatIdProps}
                    />
                )}
                {countryRule?.isFieldVisible('taxNumber') && (
                    <TextInput
                        className="flex-grow"
                        label={t('taxNumber')}
                        placeholder={t('enterPlaceholder', { label: t('taxNumber') })}
                        readOnly={readOnly}
                        required={countryRule?.isFieldMandatory('taxNumber')}
                        {...form.getInputProps('companyData.taxNumber')}
                    />
                )}
            </div>
            {countryRule?.isFieldVisible('icoNumber') && (
                <TextInput
                    className="flex-grow"
                    label={t('icoNumber')}
                    placeholder={t('enterPlaceholder', { label: t('icoNumber') })}
                    readOnly={readOnly}
                    required={countryRule?.isFieldMandatory('icoNumber')}
                    {...form.getInputProps('companyData.icoNumber')}
                />
            )}
            <h4 className="text-base-bold -mb-2 mt-6">{t('contactInformation')}</h4>
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow"
                    label={t('generalOfficeMail')}
                    placeholder={t('enterPlaceholder', { label: t('generalOfficeMail') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.generalOfficeMail')}
                />
                {countryRule?.isFieldVisible('pecEmail') && (
                    <TextInput
                        className="flex-grow"
                        label={t('pecEmail')}
                        placeholder={t('enterPlaceholder', { label: t('pecEmail') })}
                        readOnly={readOnly}
                        required={countryRule?.isFieldMandatory('pecEmail')}
                        {...form.getInputProps('companyData.pecEmail')}
                    />
                )}
            </div>
            <div className="flex gap-4">
                {countryRule?.isFieldVisible('phoneNumber') && (
                    <InputBase
                        className="flex-grow"
                        classNames={classNames.textField.default}
                        component={PhoneInput}
                        label={t('phoneNumber')}
                        placeholder={countryRule?.getPlaceholder('phoneNumber', t('phoneNumberPlaceholder'))}
                        readOnly={readOnly}
                        required={countryRule?.isFieldMandatory('phoneNumber')}
                        unstyled
                        {...form.getInputProps('companyData.phoneNumber')}
                        {...phoneNumberProps}
                        onChange={(value: string) => {
                            debounceOnChangeString(value, phoneNumberProps.onChange);
                        }}
                    />
                )}
                {countryRule?.isFieldVisible('mobileNumber') && (
                    <InputBase
                        className="flex-grow"
                        classNames={classNames.textField.default}
                        component={PhoneInput}
                        label={t('mobileNumber')}
                        placeholder={countryRule?.getPlaceholder('mobileNumber', t('phoneNumberPlaceholder'))}
                        readOnly={readOnly}
                        required={countryRule?.isFieldMandatory('mobileNumber')}
                        unstyled
                        {...form.getInputProps('companyData.mobileNumber')}
                        {...mobileNumberProps}
                        onChange={(value: string) => {
                            debounceOnChangeString(value, mobileNumberProps.onChange);
                        }}
                    />
                )}
            </div>
        </>
    );
};
