/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */

export type StepActionType = typeof StepActionType[keyof typeof StepActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepActionType = {
  FileUpload: 'FileUpload',
  FileDownload: 'FileDownload',
  EditCompanyData: 'EditCompanyData',
  ViewCompanyData: 'ViewCompanyData',
  SignContractLink: 'SignContractLink',
  DownloadContract: 'DownloadContract',
  MyDokaLink: 'MyDokaLink',
  OnlineShopLink: 'OnlineShopLink',
} as const;
