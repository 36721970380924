import { Modal } from '@mantine/core';
import { StepActionType } from '@shared/api/models';
import { useGetRequestById, useGetRequestDetail } from '@shared/api/queries/requests/requests';
import { CompanyForm } from '@shared/components/company/CompanyForm/CompanyForm';
import { ViewCompany } from '@shared/components/company/ViewCompany/ViewCompany';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SignContractForm } from 'app/request/components/SignContractForm';

type Props = {
    open: boolean;
    stepActonType: StepActionType;
    onClose: () => void;
};

export const StepActionModal = ({ open, stepActonType, onClose }: Props) => {
    const { t } = useTranslation();
    const { requestId = '' } = useParams();
    const { data: apiRequest, isLoading: isRequestLoading } = useGetRequestById(requestId, {
        query: { enabled: stepActonType === StepActionType.SignContractLink }
    });
    const { data: apiRequestDetail, isLoading: isRequestDetailLoading } = useGetRequestDetail(requestId, {
        query: {
            enabled:
                stepActonType === StepActionType.EditCompanyData || stepActonType === StepActionType.ViewCompanyData
        }
    });

    const request = apiRequest?.data;
    const requestDetail = apiRequestDetail?.data;

    if (!request) {
        return null;
    }

    const getModalTitle = (): string => {
        switch (stepActonType) {
            case StepActionType.EditCompanyData:
                return t('insertCompanyData');
            case StepActionType.ViewCompanyData:
                return t('viewCompanyData');
            case StepActionType.SignContractLink:
                return t('viewDigitalServicesUsageContract');
            default:
                return '';
        }
    };

    return (
        <Modal className="w-[960px]" opened={open} title={getModalTitle()} onClose={onClose}>
            {(isRequestDetailLoading || isRequestLoading) && <LoadingAnimation />}
            {stepActonType === StepActionType.EditCompanyData && requestDetail && (
                <CompanyForm request={requestDetail} onClose={onClose} />
            )}
            {stepActonType === StepActionType.ViewCompanyData && requestDetail && (
                <ViewCompany request={requestDetail} onClose={onClose} />
            )}
            {stepActonType === StepActionType.SignContractLink && request && (
                <SignContractForm request={request} onClose={onClose} />
            )}
        </Modal>
    );
};
