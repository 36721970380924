import { Notifications, SplitLayout, UserContextMenuItem } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router';

import { useGetInboxToken } from 'notificationsApi/queries/inbox/inbox';
import { getSenderImage } from 'notificationsApi/queries/messages/messages';
import { knockConfiguration } from 'shared/utils';
import background from './assets/welcome-bg.jpg';

export const StartLayoutFrame = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: inboxToken, isLoading: isInboxTokenPending } = useGetInboxToken();

    const getUserImageUrl = async (userId: string) => {
        const senderImageModel = await getSenderImage(userId);

        return senderImageModel?.data?.imageUri ?? undefined;
    };

    return (
        <SplitLayout
            backgroundImage={background}
            childContainerClassName="h-full justify-center items-center xs:max-w-[464px]"
            className="m-auto"
            versionNumber={APP_VERSION}
        >
            <UserContextMenuItem
                icon="domain"
                title={t('myRegistrations')}
                onClick={() => navigate('/manageRequests')}
            />
            <Notifications
                getUserImageUrl={getUserImageUrl}
                isLoading={isInboxTokenPending}
                userId={inboxToken?.data?.userIdentifier}
                userToken={inboxToken?.data?.token}
                {...knockConfiguration}
            />
            <Outlet />
        </SplitLayout>
    );
};
