import {
    AttachmentType,
    CreateInvitationNewModel,
    CreateOrUpdateCompanyDataModel,
    OnboardingRequestDetailModel,
    UpdateOnboardingRequestModel
} from 'api/models';
import { CompanyBaseDataSchemaType, InvitationSchemaType, RequestSchemaType } from './companyFormSchema';

export const getInitialRequestValues = (
    request: OnboardingRequestDetailModel,
    defaultMail: string = ''
): RequestSchemaType => {
    const company = request?.companyData;

    return {
        companyData: {
            companyName: company?.companyName ?? '',
            industrialSector: company?.industrialSectorIdentifier ?? '',
            country: company?.mainAddress?.countryConfiguration?.id ?? '',
            street: company?.mainAddress?.street ?? '',
            zipCode: company?.mainAddress?.zipCode ?? '',
            city: company?.mainAddress?.city ?? '',
            vatId: company?.vatNr ?? '',
            taxNumber: company?.taxNr ?? '',
            generalOfficeMail: company?.email ?? '',
            phoneNumber: company?.phone ?? '',
            mobileNumber: company?.mobilePhone ?? '',
            electronicInvoiceEmail: company?.electronicInvoiceEmail ?? '',
            useElectronicInvoice: company?.useElectronicInvoice ?? true,
            electronicInvoiceType: company?.electronicInvoiceType,
            icoNumber: company?.icoNr ?? '',
            pecEmail: company?.pecEmail ?? '',
            sdiCode: company?.receiverCode ?? '',
            companyOwnerName: company?.companyOwnerName ?? '',
            state: company?.mainAddress?.state ?? ''
        },
        existingCustomer: !!request?.existingCustomer,
        technicalAdministrator: request?.administrators?.[0]?.model?.email ?? defaultMail,
        contractSignatory: request?.signers?.[0]?.model?.email ?? defaultMail,
        BusinessLicense:
            request?.attachments?.find(
                (attachment) => attachment.exists && attachment.attachmentType === AttachmentType.BusinessLicense
            )?.id ?? '',
        VATCertificate:
            request?.attachments?.find(
                (attachment) => attachment.exists && attachment.attachmentType === AttachmentType.VATCertificate
            )?.id ?? '',
        RegisteredNationalAddress:
            request?.attachments?.find(
                (attachment) =>
                    attachment.exists && attachment.attachmentType === AttachmentType.RegisteredNationalAddress
            )?.id ?? ''
    };
};

export const companyToRequest = (values: RequestSchemaType): UpdateOnboardingRequestModel => ({
    administrators: values.technicalAdministrator ? [{ model: { email: values.technicalAdministrator } }] : null,
    signers: values.contractSignatory ? [{ model: { email: values.contractSignatory } }] : null,
    companyData: getCompanyData(values.companyData)
});

const getCompanyData = (companyData: CompanyBaseDataSchemaType): CreateOrUpdateCompanyDataModel => ({
    companyName: companyData.companyName,
    companyOwnerName: companyData.companyOwnerName,
    industrialSectorIdentifier: companyData.industrialSector,
    mainAddress: {
        countryConfigurationId: !companyData.country ? null : companyData.country,
        street: companyData.street,
        zipCode: companyData.zipCode,
        state: companyData.state,
        city: companyData.city
    },
    taxNr: companyData.taxNumber,
    email: companyData.generalOfficeMail,
    phone: companyData.phoneNumber,
    mobilePhone: companyData.mobileNumber,
    electronicInvoiceEmail: companyData.useElectronicInvoice ? companyData.electronicInvoiceEmail : null,
    electronicInvoiceType: companyData.useElectronicInvoice ? companyData.electronicInvoiceType : null,
    useElectronicInvoice: companyData.useElectronicInvoice,
    vatNr: companyData.vatId,
    icoNr: companyData.icoNumber,
    pecEmail: companyData.pecEmail,
    receiverCode: companyData.sdiCode
});

export const companyToInvite = (values: InvitationSchemaType): CreateInvitationNewModel => ({
    invitee: { model: { email: values.companyRegistrar } },
    companyData: getCompanyData(values.companyData)
});
