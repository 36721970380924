import { Notifications } from '@mantine/notifications';
import { getCustomThemeOverrides } from '@shared/theming/getCustomThemeOverrides';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    AuthProvider,
    DefaultErrorBoundary,
    MantineOverrideProvider,
    Theme,
    ThemeProvider,
    enableUserTracking
} from '@uag/react-core';
import { t } from 'i18next';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router';

import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { router } from './AppRoutes';
import './App.css';
import '../i18n';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { retry: false, refetchOnWindowFocus: false, refetchOnReconnect: false, refetchOnMount: false }
    }
});

enableUserTracking();

export const App = () => {
    const customThemeOverrides = getCustomThemeOverrides();

    useEffect(() => {
        document.title = t('pageTitle');
    }, []);

    return (
        <ThemeProvider mail="registration@doka.com" theme={Theme.Doka}>
            <MantineOverrideProvider customThemeOverrides={customThemeOverrides}>
                <AuthProvider configuration={configurationIdentityServer}>
                    <QueryClientProvider client={queryClient}>
                        <AppInsightsContextProvider>
                            <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                <Notifications />
                                <RouterProvider router={router} />
                            </DefaultErrorBoundary>
                        </AppInsightsContextProvider>
                    </QueryClientProvider>
                </AuthProvider>
            </MantineOverrideProvider>
        </ThemeProvider>
    );
};
